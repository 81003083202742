import { CalculatorFuelSavingsProps } from '@/components/organisms/PaymentCalculator/types';
import {
  calculatedMonthlyPaymentFinance,
  calculatedMonthlyPaymentLease,
  calculateMSRPFinance,
  calculateMSRPLease,
} from '@/lib/calculatedMonthlyPayment';
import {
  CALC_FINANCE_INITIAL_APR,
  CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT,
  CALC_FINANCE_LOAN_TERM_OPTIONS,
  CALC_INITIAL_FINANCE_TERM_INDEX,
  CALC_INITIAL_LEASE_TERM_INDEX,
  CALC_LEASE_INITIAL_DOWN_PAYMENT_PERCENT,
  CALC_LEASE_INITIAL_INTEREST_RATE,
  CALC_LEASE_INITIAL_RESIDUAL_VALUE_PERCENT,
  CALC_LEASE_LOAN_TERM_OPTIONS,
  CALC_LEASE_MILEAGE_OPTIONS,
} from '@/lib/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useCalculator = ({
  type,
  mode,
  salePrice,
  setSalePrice,
  incentivesTotal,
  fuelSavings,
  setMode,
  onChangeMonthlyEstimatedPayment,
  onChangeTerm,
  displayFuelSavings,
  model,
}: {
  type: 'vlp' | 'vdp' | 'homepage';
  mode: 'Finance' | 'Lease';
  salePrice: number;
  setSalePrice?: (value: number) => void;
  incentivesTotal?: number;
  fuelSavings?: CalculatorFuelSavingsProps;
  setMode?: (val: 'Finance' | 'Lease') => void;
  onChangeMonthlyEstimatedPayment?: (value: number) => void;
  onChangeTerm?: (value: string) => void;
  displayFuelSavings?: boolean;
  model?: string;
}) => {
  const isFinance = mode === 'Finance';

  const initialDownPaymentPercent = isFinance
    ? CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT
    : CALC_LEASE_INITIAL_DOWN_PAYMENT_PERCENT;

  const initialLoanTerm = isFinance
    ? CALC_FINANCE_LOAN_TERM_OPTIONS[CALC_INITIAL_FINANCE_TERM_INDEX]
    : CALC_LEASE_LOAN_TERM_OPTIONS[CALC_INITIAL_LEASE_TERM_INDEX];

  const initialAprOrInterestRate = isFinance
    ? CALC_FINANCE_INITIAL_APR
    : CALC_LEASE_INITIAL_INTEREST_RATE;

  let initialResidualValue = CALC_LEASE_INITIAL_RESIDUAL_VALUE_PERCENT;

  if (model) {
    const upperCaseModelName = model.toUpperCase();
    if (upperCaseModelName.includes('HUMMER')) {
      initialResidualValue = 0.75;
    } else if (upperCaseModelName.includes('LYRIQ')) {
      initialResidualValue = 0.8;
    } else if (upperCaseModelName.includes('SIERRA EV')) {
      initialResidualValue = 0.58;
    }
  }

  const mileageOptions = CALC_LEASE_MILEAGE_OPTIONS;
  const [mileageFactorIndex, setMileageFactorIndex] = useState<number>(1);
  const [downPaymentType, setDownPaymentType] = useState<'%' | '$'>('%');
  const [downPayment, setDownPayment] = useState<number>(
    type === 'vlp' ? 0 : initialDownPaymentPercent * (salePrice || 0)
  );
  const [downPaymentPercent, setDownPaymentPercent] = useState<number>(
    type === 'vlp' ? 0 : initialDownPaymentPercent * 100
  );

  const [loanOrLeaseTerm, setLoanOrLeaseTerm] =
    useState<string>(initialLoanTerm);
  const [aprOrInterestRate, setAprOrInterestRate] = useState<number>(
    type === 'vlp' ? 0 : initialAprOrInterestRate
  );

  const [tradeInValue, setTradeInValue] = useState<number>(0);

  const [residualValueType, setResidualValueType] = useState<'%' | '$'>('%');
  const [residualPercent, setResidualPercent] = useState<number>(
    initialResidualValue * 100
  );
  const [residualValue, setResidualValue] = useState<number>(
    initialResidualValue * (salePrice || 0)
  );

  const calculateTotalAfterDeductions = useCallback(() => {
    return (
      salePrice -
      downPayment -
      (isFinance ? tradeInValue : 0) -
      (incentivesTotal || 0)
    );
  }, [salePrice, downPayment, tradeInValue, incentivesTotal, isFinance]);

  const [totalAfterDeductions, setTotalAfterDeductions] = useState<number>(
    calculateTotalAfterDeductions()
  );

  const calculateMonthlyPayment = useCallback(() => {
    if (isFinance) {
      return calculatedMonthlyPaymentFinance(
        aprOrInterestRate,
        totalAfterDeductions,
        Number(loanOrLeaseTerm),
        fuelSavings && displayFuelSavings
          ? {
              fuelSavingsEstimate: Number(fuelSavings.fuelSavingsEstimate),
              timePeriod: Number(fuelSavings.timePeriod),
            }
          : undefined
      );
    }

    const mileageResidualPercentageFactor = Number(
      mileageOptions[mileageFactorIndex].value
    );
    const adjustedResidualValue = Number(
      (Number(mileageResidualPercentageFactor) * salePrice) / 100
    );

    return calculatedMonthlyPaymentLease({
      apr: aprOrInterestRate,
      totalAfterDeductions,
      leaseTerm: Number(loanOrLeaseTerm),
      residualValue: residualValue + adjustedResidualValue,
      fuelSavings:
        fuelSavings && displayFuelSavings
          ? {
              fuelSavingsEstimate: Number(fuelSavings.fuelSavingsEstimate),
              timePeriod: Number(fuelSavings.timePeriod),
            }
          : undefined,
    });
  }, [
    isFinance,
    aprOrInterestRate,
    totalAfterDeductions,
    loanOrLeaseTerm,
    residualValue,
    fuelSavings,
    displayFuelSavings,
    mileageFactorIndex,
    mileageOptions,
    salePrice,
  ]);

  const calculateMonthlyPaymentNoEVSavings = useCallback(() => {
    if (isFinance) {
      return calculatedMonthlyPaymentFinance(
        aprOrInterestRate,
        totalAfterDeductions + (incentivesTotal || 0),
        Number(loanOrLeaseTerm)
      );
    }
    const mileageResidualPercentageFactor = Number(
      mileageOptions[mileageFactorIndex].value
    );
    const adjustedResidualValue = Number(
      (Number(mileageResidualPercentageFactor) * salePrice) / 100
    );
    return calculatedMonthlyPaymentLease({
      apr: aprOrInterestRate,
      totalAfterDeductions: totalAfterDeductions + (incentivesTotal || 0),
      leaseTerm: Number(loanOrLeaseTerm),
      residualValue: residualValue + adjustedResidualValue,
    });
  }, [
    isFinance,
    aprOrInterestRate,
    totalAfterDeductions,
    loanOrLeaseTerm,
    residualValue,
    incentivesTotal,
    mileageFactorIndex,
    mileageOptions,
    salePrice,
  ]);

  const [monthlyEstimatedPayment, setMonthlyEstimatedPayment] =
    useState<number>(calculateMonthlyPayment());
  const [
    monthlyEstimatedPaymentNoEVSavings,
    setMonthlyEstimatedPaymentNoEVSavings,
  ] = useState<number>(calculateMonthlyPaymentNoEVSavings());

  useEffect(() => {
    const newTotalAfterDeductions = calculateTotalAfterDeductions();
    setTotalAfterDeductions(newTotalAfterDeductions);

    const newMonthlyEstimate = calculateMonthlyPayment();
    setMonthlyEstimatedPayment(newMonthlyEstimate);

    const newMonthlyEstimateNoDiscount = calculateMonthlyPaymentNoEVSavings();
    setMonthlyEstimatedPaymentNoEVSavings(newMonthlyEstimateNoDiscount);

    onChangeMonthlyEstimatedPayment?.(newMonthlyEstimate);
    onChangeTerm?.(loanOrLeaseTerm);
  }, [
    salePrice,
    aprOrInterestRate,
    downPayment,
    loanOrLeaseTerm,
    residualValue,
    fuelSavings,
    calculateTotalAfterDeductions,
    calculateMonthlyPayment,
    onChangeMonthlyEstimatedPayment,
    onChangeTerm,
    calculateMonthlyPaymentNoEVSavings,
  ]);

  const handleChangeMonthlyEstimatedPayment = useCallback(
    (inputMonthlyEstimatedPayment: string) => {
      const newMEP = Number(inputMonthlyEstimatedPayment);

      setMonthlyEstimatedPayment(newMEP);
      let newSP;
      if (isFinance) {
        newSP = calculateMSRPFinance(
          newMEP,
          aprOrInterestRate,
          Number(loanOrLeaseTerm),
          downPayment,
          tradeInValue,
          incentivesTotal,
          fuelSavings
            ? {
                fuelSavingsEstimate: Number(fuelSavings.fuelSavingsEstimate),
                timePeriod: Number(fuelSavings.timePeriod),
              }
            : undefined
        );
      } else {
        newSP = calculateMSRPLease(
          newMEP,
          aprOrInterestRate,
          Number(loanOrLeaseTerm),
          residualValue,
          downPayment,
          incentivesTotal,
          fuelSavings
            ? {
                fuelSavingsEstimate: Number(fuelSavings.fuelSavingsEstimate),
                timePeriod: Number(fuelSavings.timePeriod),
              }
            : undefined
        );
      }

      setSalePrice?.(newSP);
      const newTotalAfterDeductions = calculateTotalAfterDeductions();
      setTotalAfterDeductions(newTotalAfterDeductions);
      if (downPaymentType === '%') {
        setDownPaymentPercent((downPayment / newSP) * 100);
      }
    },
    [
      aprOrInterestRate,
      loanOrLeaseTerm,
      downPayment,
      tradeInValue,
      setSalePrice,
      calculateTotalAfterDeductions,
      setTotalAfterDeductions,
      downPaymentType,
      fuelSavings,
      incentivesTotal,
      isFinance,
      residualValue,
    ]
  );

  const handleChangeAprOrInterestRate = useCallback(
    (value: string) => {
      const newRate = Number(value);
      if (newRate <= 100) {
        setAprOrInterestRate(newRate);
      }
    },
    [setAprOrInterestRate]
  );

  const handleChangeDownPayment = useCallback(
    (value: string) => {
      let newValue = Number(value);
      if (downPaymentType === '$') {
        setDownPayment(Number(newValue));
        const percent = (newValue / salePrice) * 100;
        setDownPaymentPercent(percent);
      } else if (newValue <= 100) {
        const percent = newValue;
        newValue = (percent / 100) * salePrice;
        setDownPaymentPercent(percent);
        setDownPayment(newValue);
      }
    },
    [downPaymentType, salePrice, setDownPayment, setDownPaymentPercent]
  );

  const handleChangeDownPaymentType = useCallback(
    (value: '%' | '$') => {
      const newValue = Number((downPaymentPercent * salePrice) / 100);
      const newPercentage = Number((downPayment / salePrice) * 100);
      setDownPayment(newValue);
      setDownPaymentPercent(Math.min(100, newPercentage));
      setDownPaymentType(value);
    },
    [
      downPayment,
      downPaymentPercent,
      salePrice,
      setDownPayment,
      setDownPaymentPercent,
      setDownPaymentType,
    ]
  );

  const handleChangeSalePrice = useCallback(
    (value: string) => {
      const newSalePrice = Number(value);
      setSalePrice?.(newSalePrice);
      setTotalAfterDeductions(calculateTotalAfterDeductions());
      if (downPaymentType === '%') {
        setDownPayment((newSalePrice * downPaymentPercent) / 100);
      }
      if (downPaymentType === '$') {
        setDownPaymentPercent((downPayment / newSalePrice) * 100);
      }
    },
    [
      setSalePrice,
      calculateTotalAfterDeductions,
      downPayment,
      downPaymentType,
      downPaymentPercent,
      setDownPaymentPercent,
      setDownPayment,
    ]
  );

  const handleChangeTerm = useCallback(
    (term: string) => {
      setLoanOrLeaseTerm(term);
    },
    [setLoanOrLeaseTerm]
  );

  const handleChangeTradeInValue = useCallback(
    (value: string) => {
      setTradeInValue(Number(value));
    },
    [setTradeInValue]
  );

  const handleChangeResidualValue = useCallback(
    (value: string) => {
      let newValue;
      if (residualValueType === '$') {
        newValue = Number(value);
        setResidualValue(newValue);
        setResidualPercent(Number((newValue / salePrice) * 100));
      } else {
        newValue = Number((Number(value) * salePrice) / 100);
        setResidualPercent(Number(value));
        setResidualValue(newValue);
      }
    },
    [residualValueType, setResidualValue, setResidualPercent, salePrice]
  );

  const handleChangeResidualValueType = useCallback(
    (value: '%' | '$') => {
      setResidualValue(Number((residualPercent * salePrice) / 100));
      setResidualValueType(value);
    },
    [residualPercent, salePrice, setResidualValue, setResidualValueType]
  );

  const handleChangeMileageFactor = useCallback(
    (newMileageFactorIndex: number) => {
      setMileageFactorIndex(Number(newMileageFactorIndex));
    },
    [setMileageFactorIndex]
  );

  const handleChangeMode = useCallback(
    (val: 'Finance' | 'Lease') => {
      if (setMode) {
        setMode(val);
        if (val === 'Finance') {
          setAprOrInterestRate(CALC_FINANCE_INITIAL_APR);
          setDownPaymentPercent(
            CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT * 100
          );
          setDownPayment(CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT * salePrice);
          setLoanOrLeaseTerm(
            CALC_FINANCE_LOAN_TERM_OPTIONS[CALC_INITIAL_FINANCE_TERM_INDEX]
          );
        } else {
          setAprOrInterestRate(CALC_LEASE_INITIAL_INTEREST_RATE);
          setDownPaymentPercent(CALC_LEASE_INITIAL_DOWN_PAYMENT_PERCENT * 100);
          setDownPayment(CALC_LEASE_INITIAL_DOWN_PAYMENT_PERCENT * salePrice);
          setLoanOrLeaseTerm(
            CALC_LEASE_LOAN_TERM_OPTIONS[CALC_INITIAL_LEASE_TERM_INDEX]
          );
        }
      }
    },
    [setMode, salePrice]
  );

  const invalidDownPayment = useMemo(
    () =>
      (downPaymentType === '$' && downPayment >= salePrice) ||
      (downPaymentType === '%' &&
        (downPaymentPercent >= 100 || downPaymentPercent < 0)),
    [downPayment, downPaymentPercent, downPaymentType, salePrice]
  );

  return {
    downPaymentType,
    downPayment,
    downPaymentPercent,
    invalidDownPayment,
    tradeInValue,
    loanOrLeaseTerm,
    aprOrInterestRate,
    monthlyEstimatedPayment,
    residualValueType,
    residualValue,
    residualPercent,
    totalAfterDeductions,
    mileageFactorIndex,
    monthlyEstimatedPaymentNoEVSavings,
    handleChangeMonthlyEstimatedPayment,
    handleChangeDownPayment,
    handleChangeDownPaymentType,
    handleChangeSalePrice,
    handleChangeTerm,
    handleChangeTradeInValue,
    handleChangeAprOrInterestRate,
    handleChangeMode,
    handleChangeResidualValue,
    handleChangeResidualValueType,
    handleChangeMileageFactor,
  };
};
