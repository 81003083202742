import { ButtonVariants } from '@/components/atoms/Button/Button';
import ButtonLink from '@/components/atoms/ButtonLink/ButtonLink';
import { Skeleton } from '@/components/atoms/Skeleton';
import { useInView } from 'react-intersection-observer';
import { useArticles } from '../../../hooks/queries/articles/useArticles';
import useWindowSize from '../../../hooks/useWindowSize';
import TextButtonLink from '../../atoms/TextButtonLink/TextButtonLink';
import { BlogListingLink } from '../BlogListingLink';

export const HomePageRecentArticles = () => {
  const { ref, inView } = useInView();
  const { isMobile } = useWindowSize();

  const { data, isFetching, isError, isFetched } = useArticles({
    queryKey: ['recentArticles'],
    limit: 3,
    enabled: inView,
  });

  const renderContent = () => {
    if (isError) {
      return (
        <p className="text-body1Light">
          There was a problem showing articles. Please refresh and try again.
        </p>
      );
    }

    if (isFetching) {
      return (
        <div className="flex flex-col gap-m bg-white p-l ml:flex-row">
          <div className="ml:w-1/2">
            <Skeleton className="h-[140px] w-full ml:h-[300px]" />
          </div>
          <div className="flex flex-col gap-m ml:w-1/2">
            <Skeleton className="h-[140px] w-full" />
            <Skeleton className="h-[140px] w-full" />
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-m ml:flex-row">
        <div className="ml:max-w-[374px]">
          {data && data.length > 0 && (
            <BlogListingLink
              blog={data[0]}
              layout="column"
              showExcerpt={!isMobile}
              titleVariant={isMobile ? 'small' : 'large'}
            />
          )}
        </div>
        <div className="flex flex-1 flex-col gap-m ml:justify-between">
          {data &&
            data.slice(1).map((article) => (
              <div key={article.id} className="flex flex-1">
                <BlogListingLink
                  blog={article}
                  layout={isMobile ? 'column' : 'row'}
                  showExcerpt={!isMobile}
                  titleVariant={isMobile ? 'small' : 'large'}
                />
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className="flex flex-col gap-[12px] bg-neutral-200 px-l py-[80px] ml:px-[60px] lg:py-[76px]"
      ref={isFetched ? undefined : ref}
    >
      {!isError && (
        <div className="flex flex-row justify-between">
          <h4 className="text-h4SemiBold text-brandMidnight">Recent news</h4>
          <div className="hidden ml:block">
            <TextButtonLink href="/news" aria-label="Read more articles">
              Read more
            </TextButtonLink>
          </div>
        </div>
      )}
      {renderContent()}
      {isFetched && !!data && (
        <div className="block ml:hidden">
          <ButtonLink
            href="/news"
            variant={ButtonVariants.Secondary}
            aria-label="Read more articles"
          >
            Read more
          </ButtonLink>
        </div>
      )}
    </div>
  );
};
