import useWindowSize from '@/hooks/useWindowSize';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useEffect, useRef } from 'react';

import Image from '@/components/atoms/Image';
import { Swiper as SwiperClass } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

export interface ImageCarouselProps {
  images: string[];
  selectedImageIndex?: number;
  showArrows?: boolean;
  showIndicators?: boolean;
  updateCurrentIndex?: (index: number) => void;
  loop?: boolean;
  navCarousel?: boolean;
  fullWidth?: boolean;
}

const CustomRenderArrow = ({
  hasNext,
  dir,
  onClick,
}: {
  hasNext: boolean;
  dir: 'next' | 'prev';
  onClick: () => void;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  if (!hasNext) return null;
  const arrowClass = 'w-[20px] text-neutral-0 h-[20px]';

  return (
    <button
      ref={buttonRef}
      aria-label={`${dir === 'next' ? 'Next' : 'Previous'} picture`}
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className="flex h-3xl w-3xl items-center justify-center rounded-[100%] border border-neutral-0 p-[10px] opacity-40 transition-opacity duration-250 m:hover:opacity-100"
    >
      {dir === 'next' ? (
        <ChevronRightIcon className={arrowClass} />
      ) : (
        <ChevronLeftIcon className={arrowClass} />
      )}
    </button>
  );
};

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  selectedImageIndex,
  loop = false,
  showArrows = true,
  updateCurrentIndex = () => {},
  navCarousel = false,
  fullWidth = false,
}) => {
  const { isXS, isMobile } = useWindowSize();
  const swiperRef = useRef<SwiperClass | null>(null);
  const thumbSwiperRef = useRef<SwiperClass | null>(null);
  const [imageWidth, setImageWidth] = React.useState(360);

  const handleSlideChange = useCallback(
    (swiper: SwiperClass) => {
      const activeSlideIndex = swiper.realIndex;
      updateCurrentIndex(activeSlideIndex);

      const activeSlide = thumbSwiperRef.current?.slides?.[activeSlideIndex];
      const slides = thumbSwiperRef.current?.slides;

      if (activeSlide && slides) {
        for (let i = 0; i < slides.length; i += 1) {
          if (i !== activeSlideIndex) {
            slides[i].style.opacity = '0.4';
            slides[i].style.border = 'none';
          }
        }
        activeSlide.style.opacity = '1';
        activeSlide.style.border = '2px solid #0EDAB5';

        const swiperWrapper = thumbSwiperRef.current?.wrapperEl;

        const activeX =
          activeSlide.getBoundingClientRect().x -
          (swiperWrapper?.getBoundingClientRect().x || 0);

        const half =
          (swiperWrapper?.getBoundingClientRect().width || imageWidth) / 2;
        const endBound = isXS ? 2 : 3;

        if (swiperWrapper) {
          if (activeSlideIndex <= 1) {
            swiperWrapper.style.transform = `translate3d(0px, 0px, 0px)`;
          } else if (
            activeX > half &&
            activeSlideIndex < slides.length - endBound
          ) {
            const dif = activeX - half + 60;
            swiperWrapper.style.transform = `translate3d(-${dif}px, 0px, 0px)`;
          }
        }
      }
    },
    [imageWidth, updateCurrentIndex, isXS]
  );

  const handleSlideNext = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  }, []);
  const handleSlidePrev = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  }, []);

  useEffect(() => {
    const handlePressKey = (evt: KeyboardEvent) => {
      if (evt.key === 'ArrowRight') {
        handleSlideNext();
      }
      if (evt.key === 'ArrowLeft') {
        handleSlidePrev();
      }
    };
    document.addEventListener('keydown', handlePressKey);
    return () => {
      document.removeEventListener('keydown', handlePressKey);
    };
  }, [handleSlideNext, handleSlidePrev]);

  useEffect(() => {
    const swiper = swiperRef.current;
    const thumb = thumbSwiperRef.current;
    if (selectedImageIndex !== undefined && swiper) {
      swiper.wrapperEl.style.transition = 'none';
      swiper.slideToLoop(selectedImageIndex);
      swiper.wrapperEl.style.transition = '';

      if (thumb) {
        thumb.wrapperEl.style.transition = 'none';
        thumb.slideToLoop(selectedImageIndex);
        thumb.wrapperEl.style.transition = '';
      }
    }
  }, [selectedImageIndex]);

  useEffect(() => {
    const handleResize = () => {
      if (fullWidth) {
        setImageWidth(window.innerWidth);
      } else {
        // image is atleast 360px wide
        // image expands to margin at 1:0.7 aspect ratio
        // height is limited if there is nav carousel
        // 160 == 2 nav buttons, inner + outter margin
        // 360 == min width (mobile screen width)
        setImageWidth(
          Math.max(
            Math.min(
              (window.innerHeight / 0.7) * (navCarousel ? 0.8 : 1),
              window.innerWidth - (isMobile ? 40 : 160)
            ),
            360
          )
        );
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, fullWidth, navCarousel]);

  const imageHeight = imageWidth * 0.7 * (navCarousel ? 0.8 : 1);

  let thumbs = 7.5;
  if (isMobile) {
    thumbs = 5.5;
  }
  if (isXS) {
    thumbs = 3.5;
  }

  const thumbMin = Math.min(thumbs, images.length);

  return (
    <div className="relative flex h-full w-full flex-col justify-end pt-xl m:pt-0">
      <div className="absolute top-1/2 flex w-full translate-y-[-50%] transform justify-center">
        <Swiper
          loop={loop}
          spaceBetween={isMobile ? 16 : 0}
          slidesPerView={1}
          thumbs={{ swiper: thumbSwiperRef.current }}
          initialSlide={selectedImageIndex}
          onSlideChange={handleSlideChange}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {images.map((image, index) => {
            return (
              <SwiperSlide key={`swiper-${image}-${index}`}>
                <div className="flex flex-grow items-center justify-center">
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: `${imageWidth}px`,
                      height: `${imageHeight}px`,
                    }}
                  >
                    <Image
                      src={image}
                      alt={`Slide ${index + 1}`}
                      width={imageWidth}
                      height={imageHeight}
                      dynamicClassName="flex flex-grow justify-center items-center"
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {navCarousel && (
        <div className="flex w-full transform items-end justify-center m:pb-xl">
          <div
            className="swiperThumbWrapper flex w-full"
            style={{
              width: `${imageWidth}px`,
            }}
          >
            <Swiper
              spaceBetween={isMobile ? 8 : 16}
              slidesPerView={thumbMin}
              centeredSlides={true}
              centeredSlidesBounds={true}
              freeMode={true}
              watchSlidesProgress={true}
              onSwiper={(swiper) => {
                thumbSwiperRef.current = swiper;
              }}
              modules={[FreeMode, Navigation, Thumbs]}
            >
              {images.map((image, index) => (
                <SwiperSlide
                  className={`swiperThumb flex h-full w-full cursor-pointer items-center justify-center overflow-hidden rounded-xsmall opacity-40`}
                  key={`swiper-thumb-${image}-${index}`}
                >
                  <div className="flex h-full w-full items-center justify-center">
                    <div
                      className="flex"
                      style={{
                        width: `${imageWidth / thumbMin}px`,
                        height: `${(imageWidth / thumbMin) * 0.7}px`,
                      }}
                    >
                      <Image
                        src={image}
                        alt={`Slide Thumb ${index + 1}`}
                        fill
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}

      {showArrows && images.length > 1 && (
        <>
          <div className="absolute left-l top-[45%] z-10 hidden -translate-y-1/2 transform ml:flex">
            <CustomRenderArrow
              dir={'prev'}
              hasNext={true}
              onClick={() => {
                handleSlidePrev();
              }}
            />
          </div>
          <div className="absolute right-l top-[45%] z-10 hidden -translate-y-1/2 transform ml:flex">
            <CustomRenderArrow
              dir={'next'}
              hasNext={true}
              onClick={() => {
                handleSlideNext();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCarousel;
