import ImageCarousel, {
  ImageCarouselProps,
} from '@/components/molecules/ImageCarousel';
import { useLockScroll } from '@/hooks/useLockScroll';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { ReactNode, useEffect, useRef, useState } from 'react';

interface ImageCarouselPopupProps extends ImageCarouselProps {
  open: boolean;
  handleClose: () => void;
  actions?: ReactNode;
  updateCurrentIndex?: (index: number) => void;
  currentIndex?: number;
  caption?: string;
  navCarousel?: boolean;
}

export const ImageCarouselPopup: React.FC<ImageCarouselPopupProps> = ({
  open,
  handleClose,
  images,
  selectedImageIndex,
  showIndicators = true,
  actions,
  updateCurrentIndex,
  currentIndex = 0,
  caption,
  navCarousel = true,
}) => {
  const [index, setIndex] = useState(currentIndex);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const actionsRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  useLockScroll(open);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;
      if (
        wrapperRef.current &&
        wrapperRef.current.contains(target) &&
        !carouselRef.current?.contains(target) &&
        !actionsRef.current?.contains(target)
      ) {
        updateCurrentIndex?.(index);
        handleClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, handleClose, updateCurrentIndex, index]);

  useEffect(() => {
    const handlePressKey = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') {
        updateCurrentIndex?.(index);
        handleClose();
      }
    };
    document.addEventListener('keydown', handlePressKey);
    return () => {
      document.removeEventListener('keydown', handlePressKey);
    };
  }, [handleClose, updateCurrentIndex, index]);

  return (
    <section
      className={clsx('bottom-0 left-0 right-0 top-0 z-[100] bg-black', {
        fixed: open,
        hidden: !open,
      })}
    >
      <div
        ref={wrapperRef}
        className="relative flex h-full w-full flex-col items-center justify-start"
      >
        <div
          className={clsx('fixed top-0 z-10 flex w-full items-center p-xl', {
            'justify-between': !!actions || currentIndex !== undefined,
            'justify-end': !actions && currentIndex === undefined,
          })}
        >
          {!!actions && (
            <div ref={actionsRef} className="left-l m:left-2xl l:left-[48px]">
              {actions}
            </div>
          )}
          {currentIndex !== undefined && (
            <div className="left-1/2 flex text-body1Light text-neutral-0">
              {index + 1}/{images.length}
            </div>
          )}

          <button
            aria-label="Close Image Carousel"
            className="right-l flex h-3xl w-3xl items-center justify-center rounded-full border border-transparent bg-neutral-800 p-[10px] hover:border-neutral-800 hover:bg-neutral-900 m:right-2xl l:right-[48px]"
            onClick={handleClose}
          >
            <XMarkIcon stroke="#FFFFFF" width={28} height={28} />
          </button>
        </div>

        <div
          ref={carouselRef}
          className={clsx(
            'relative flex h-full w-full flex-col justify-center m:mb-0 m:justify-start',
            { 'pb-xl': !!caption, 'mb-xl': !caption }
          )}
        >
          {!!caption && (
            <div className="flex flex-col items-center justify-center gap-l">
              <span className="text-body1Light text-neutral-0">{caption}</span>
            </div>
          )}
          {images.length > 0 && (
            <ImageCarousel
              showArrows={showIndicators}
              selectedImageIndex={selectedImageIndex}
              images={images}
              updateCurrentIndex={setIndex}
              loop={true}
              navCarousel={navCarousel && images.length > 1}
            />
          )}
        </div>
      </div>
    </section>
  );
};
