import { FilterType } from '@/types/filters';
import { GasVsElectricCosts } from '@/types/fuel-type';
import { ResourcesConfig } from 'aws-amplify';
import { IconIds } from '../components/atoms/legacy/Icon';
import { AnalyticsEvent } from './handleActionTracking';

export const POSTAL_CODE_REGEXP =
  /^[abceghj-nprstvxy]\d[abceghj-nprstv-z][ -]?\d[abceghj-nprstv-z]\d$/i;

export const PASSWORD_REGEXP =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_()])[A-Za-z\d!@#$%^&_*()]{8,}$/;

export const PASSWORD_REQUIREMENTS_TEXT = [
  '8 characters minimum',
  '1 uppercase letter',
  '1 lowercase letter',
  '1 special character',
  '1 number (0-9)',
  'Does not include personal information',
];

export const SEARCH_PAGE_PATH = '/search';

export const GOOGLE_TRACK_INFO = {
  loginDirectionButton: {
    conversionLabel: 'q4f8CJmo5J8ZELyUm7c9',
    eventLabel: 'Go to login - button click - home page',
    eventName: 'go_to_login',
  },
  inventoryHomePageButton: {
    conversionLabel: 'LReCCLzI7ZsZELyUm7c9',
    eventLabel: 'Inventory - button click - home page',
    eventName: 'go_to_inventory',
  },
  compareEVHomePageButton: {
    conversionLabel: '5sduCPmh7KUZELyUm7c9',
    eventLabel: 'Compare EV - button click - home page',
    eventName: 'go_to_compare_EV',
  },
  newsHomePageButton: {
    conversionLabel: 'DAw1CImj7qUZELyUm7c9',
    eventLabel: 'News - button click - home page',
    eventName: 'go_to_news',
  },
  partnerHomePageButton: {
    conversionLabel: 'Pu3MCOvt7qUZELyUm7c9',
    eventLabel: 'Partner - button click - home page',
    eventName: 'go_to_partner',
  },
  customOrderHomePageButton: {
    conversionLabel: '4LNPCL_I7ZsZELyUm7c9',
    eventLabel: 'Custom Order - button click - home page',
    eventName: 'go_to_custom_order',
  },
  googleLoginButton: {
    conversionLabel: 'NEPeCM_f6J8ZELyUm7c9',
    eventLabel: 'Google login - button click - login page',
    eventName: 'login_google',
  },
  googleSignUpButton: {
    conversionLabel: 'UftcCNjf6J8ZELyUm7c9',
    eventLabel: 'Google signup - button click - signup page',
    eventName: 'signup_google',
  },
  appleLoginButton: {
    conversionLabel: 'asbNCNLf6J8ZELyUm7c9',
    eventLabel: 'Apple login - button click - login page',
    eventName: 'login_apple',
  },
  appleSignUpButton: {
    conversionLabel: 'lyXFCNvf6J8ZELyUm7c9',
    eventLabel: 'Apple signup - button click - signup page',
    eventName: 'signup_apple',
  },
  facebookLoginButton: {
    conversionLabel: 'r8rECO7F77cZELyUm7c9',
    eventLabel: 'Facebook login - button click - login page',
    eventName: 'login_facebook',
  },
  facebookSignUpButton: {
    conversionLabel: 'ixCACIe917gZELyUm7c9',
    eventLabel: 'Facebook signup - button click - login page',
    eventName: 'signup_facebook',
  },
  microsoftPersonalLoginButton: {
    conversionLabel: 'w8hPCNuVib0ZELyUm7c9',
    eventLabel: 'Microsoft Personal Login - button click - login page',
    eventName: 'login_microsoft_personal',
  },
  microsoftPersonalSignUpButton: {
    conversionLabel: '6SfbCNnYib0ZELyUm7c9',
    eventLabel: 'Microsoft Personal signup - button click - login page',
    eventName: 'signup_microsoft_personal',
  },
  emailLoginButton: {
    conversionLabel: '4l7oCNXf6J8ZELyUm7c9',
    eventLabel: 'Email login - button click - login page',
    eventName: 'login_email',
  },
  emailSignUpButton: {
    conversionLabel: 'OO2OCN7f6J8ZELyUm7c9',
    eventLabel: 'Email signup - button click - signup page',
    eventName: 'signup_email',
  },
  createAccountButton: {
    conversionLabel: 'd1ZYCOHf6J8ZELyUm7c9',
    eventLabel: 'Create account - button click - email signup page',
    eventName: 'create_account',
  },
  googleLoginSuccess: {
    conversionLabel: 'qC6KCMzj4u8ZELyUm7c9',
    eventLabel: 'Google login - success - login page',
    eventName: 'google_login_success',
  },
  googleLoginFail: {
    conversionLabel: 'eme2CODu4u8ZELyUm7c9',
    eventLabel: 'Google login - fail - login page',
    eventName: 'google_login_fail',
  },
  googleSignupSuccess: {
    conversionLabel: 'VGx3COOemfAZELyUm7c9',
    eventLabel: 'Google signup - success - signup page',
    eventName: 'google_signup_success',
  },
  googleSignupFail: {
    conversionLabel: 'sxgVCOaemfAZELyUm7c9',
    eventLabel: 'Google signup - fail - signup page',
    eventName: 'google_signup_fail',
  },
  facebookLoginSuccess: {
    conversionLabel: 'sQwHCMq11e8ZELyUm7c9',
    eventLabel: 'Facebook login - success - login page',
    eventName: 'facebook_login_success',
  },
  facebookLoginFail: {
    conversionLabel: '9KoRCOL4lvAZELyUm7c9',
    eventLabel: 'Facebook login - fail - login page',
    eventName: 'facebook_login_fail',
  },
  facebookSignupSuccess: {
    conversionLabel: 'mahoCOmemfAZELyUm7c9',
    eventLabel: 'Facebook signup - success - signup page',
    eventName: 'facebook_signup_success',
  },
  facebookSignupFail: {
    conversionLabel: 'MyvACOyemfAZELyUm7c9',
    eventLabel: 'Facebook signup - fail - signup page',
    eventName: 'facebook_signup_fail',
  },
  appleLoginSuccess: {
    conversionLabel: 'xybjCOX4lvAZELyUm7c9',
    eventLabel: 'Apple login - success - login page',
    eventName: 'apple_login_success',
  },
  appleLoginFail: {
    conversionLabel: 'xEUtCOj4lvAZELyUm7c9',
    eventLabel: 'Apple login - fail - login page',
    eventName: 'apple_login_fail',
  },
  appleSignupSuccess: {
    conversionLabel: 'JelKCO-emfAZELyUm7c9',
    eventLabel: 'Apple signup - success - signup page',
    eventName: 'apple_signup_success',
  },
  appleSignupFail: {
    conversionLabel: 'EKeoCPKemfAZELyUm7c9',
    eventLabel: 'Apple signup - fail - signup page',
    eventName: 'apple_signup_fail',
  },
  microsoftLoginSuccess: {
    conversionLabel: '93-hCOv4lvAZELyUm7c9',
    eventLabel: 'Microsoft Personal Login - success - login page',
    eventName: 'microsoft_personal_login_success',
  },
  microsoftLoginFail: {
    conversionLabel: 'cRBXCO74lvAZELyUm7c9',
    eventLabel: 'Microsoft Personal Login - fail - login page',
    eventName: 'microsoft_personal_login_fail',
  },
  microsoftSignupSuccess: {
    conversionLabel: 'FJBTCPWemfAZELyUm7c9',
    eventLabel: 'Microsoft Personal signup - success - signup page',
    eventName: 'microsoft_personal_signup_success',
  },
  microsoftSignupFail: {
    conversionLabel: 'i1cVCPiemfAZELyUm7c9',
    eventLabel: 'Microsoft Personal signup - fail - signup page',
    eventName: 'microsoft_personal_signup_fail',
  },
  emailLoginSuccess: {
    conversionLabel: 'GdueCM_qlaMZELyUm7c9',
    eventLabel: 'Email login - success - login page',
    eventName: 'email_login_success',
  },
  emailLoginFail: {
    conversionLabel: 'EiFOCNmGlqMZELyUm7c9',
    eventLabel: 'Email login - fail - login page',
    eventName: 'email_login_fail',
  },
  emailSignUpSuccess: {
    conversionLabel: 'ZHQHCNvL3KcZELyUm7c9',
    eventLabel: 'Email signup - success - signup page',
    eventName: 'email_signup_success',
  },
  emailSignUpFail: {
    conversionLabel: 'xgaUCN7L3KcZELyUm7c9',
    eventLabel: 'Email signup - fail - signup page',
    eventName: 'email_signup_fail',
  },
  sendMessageButton: {
    conversionLabel: '3k1ICMTM-J8ZELyUm7c9',
    eventLabel: 'Send dealer message - button click - contact dealer form',
    eventName: 'send_dealer_message',
  },
  sendMessageFail: {
    conversionLabel: '-FWqCO-PvaIZELyUm7c9',
    eventLabel: 'Send dealer message - fail - contact dealer form',
    eventName: 'send_dealer_message_fail',
  },
  sendMessageSuccess: {
    conversionLabel: 'AGXqCOyPvaIZELyUm7c9',
    eventLabel: 'Send dealer message - success - contact dealer form',
    eventName: 'send_dealer_message_success',
  },
  getOfferButton: {
    conversionLabel: 'aerICL2-76UZELyUm7c9',
    eventLabel: 'Get offer - button click - custom order page',
    eventName: 'custom_order_get_offer',
  },
  getOfferSuccess: {
    conversionLabel: 'nNYtCK_UuaYZELyUm7c9',
    eventLabel: 'Get offer - success - custom order page',
    eventName: 'custom_order_get_offer_success',
  },
  getOfferFail: {
    conversionLabel: 'rNYQCLLUuaYZELyUm7c9',
    eventLabel: 'Get offer - fail - custom order page',
    eventName: 'custom_order_get_offer_fail',
  },
  exploreVehicleButton: {
    conversionLabel: 'Nnh4CKTiu6YZELyUm7c9',
    eventLabel: 'Explore vehicle - button click - home page',
    eventName: 'explore_vehicle_cta',
  },
  popularMakesButton: {
    conversionLabel: 'YZ1hCIyq1aYZELyUm7c9',
    eventLabel: 'Popular makes - button click - home page',
    eventName: 'popular_makes_cta',
  },
  frequentlyAskedQuestionsButton: {
    conversionLabel: 'pODMCM7JkacZELyUm7c9',
    eventLabel: 'Frequently Asked Questions - button click',
    eventName: 'frequently_asked_questions_cta',
  },
  frequentlyAskedQuestionsFooter: {
    conversionLabel: 'Gd0ICLvAlb4ZELyUm7c9',
    eventLabel: 'Frequently Asked Questions - footer link click - home page',
    eventName: 'frequently_asked_questions_footer',
  },
  readyToConnectEmailSubmitButton: {
    conversionLabel: 'YRgsCOfbj6cZELyUm7c9',
    eventLabel: 'Ready to connect - email submit - home page',
    eventName: 'ready_to_connect_email_submit',
  },
  readyToConnectEmailSubmitSuccess: {
    conversionLabel: 'KnQkCOrbj6cZELyUm7c9',
    eventLabel: 'Ready to connect - email submit success - home page',
    eventName: 'ready_to_connect_email_submit_success',
  },
  readyToConnectEmailSubmitFail: {
    conversionLabel: 'x6EeCJffj6cZELyUm7c9',
    eventLabel: 'Ready to connect - email submit fail - home page',
    eventName: 'ready_to_connect_email_submit_fail',
  },
  forwardToModelPageImageButton: {
    conversionLabel: 'hKNCCO_YkKcZELyUm7c9',
    eventLabel: 'Forward to model page - image click - home page',
    eventName: 'forward_to_model_page',
  },
  termsAndConditionsFooterLink: {
    conversionLabel: 'vmHhCPretacZELyUm7c9',
    eventLabel: 'Terms and conditions - footer link click - home page',
    eventName: 'terms_and_conditions_footer_link',
  },
  manageCookiesFooterLink: {
    conversionLabel: 'uV9fCP3etacZELyUm7c9',
    eventLabel: 'Manage cookies - footer link click - home page',
    eventName: 'manage_cookies_footer_link',
  },
  privacyPolicyFooterLink: {
    conversionLabel: 'Ifs2CIDftacZELyUm7c9',
    eventLabel: 'Privacy policy - footer link click - home page',
    eventName: 'privacy_policy_footer_link',
  },
  blogArticlePageButton: {
    conversionLabel: 'ZJ3eCJH6tacZELyUm7c9',
    eventLabel: 'Blog article page - button click - news page',
    eventName: 'blog_article_page_button',
  },
  reviewEVBuildButton: {
    conversionLabel: 'gfzkCPeFu6cZELyUm7c9',
    eventLabel: 'Review EV build - button click - custom order page',
    eventName: 'review_EV_build_button',
  },
  contactSalesButton: {
    conversionLabel: '-Ul3CJmuuqcZELyUm7c9',
    eventLabel: 'Contact sales - button click - partner page',
    eventName: 'contact_sales_button',
  },
  partnerInfoSubmitButton: {
    conversionLabel: 'rwkCCID-uqcZELyUm7c9',
    eventLabel: 'Partner info submit - button click - partner signup page',
    eventName: 'partner_info_submit_button',
  },
  partnerInfoSubmitSuccess: {
    conversionLabel: '3W2dCPjmu6cZELyUm7c9',
    eventLabel: 'Partner info submit - success - partner signup page',
    eventName: 'partner_info_submit_success',
  },
  partnerInfoSubmitFail: {
    conversionLabel: '-FWqCO-PvaIZELyUm7c9',
    eventLabel: 'Partner info submit - fail - partner signup page',
    eventName: 'partner_info_submit_fail',
  },
  vehicleDetailPageButton: {
    conversionLabel: 'eOwxCM2V36cZELyUm7c9',
    eventLabel: 'Vehicle detail page - button click - inventory page',
    eventName: 'vehicle_detail_page_button',
  },
  chooseMakeButton: {
    conversionLabel: 'BbuJCNCV36cZELyUm7c9',
    eventLabel: 'Choose make - button click - custom order page',
    eventName: 'choose_make_button',
  },
  callDealerButton: {
    conversionLabel: 'i0DACOXRv6kZELyUm7c9',
    eventLabel: 'Call dealer - button click - vdp page',
    eventName: 'call_dealer_button',
  },
  clickHeartButton: {
    conversionLabel: '8Im5CLaV1rIZELyUm7c9',
    eventLabel: 'Save vehicle - button click',
    eventName: 'click_heart_button',
  },
  clickSavedVehicleCard: {
    conversionLabel: 'USd5CM611rIZELyUm7c9',
    eventLabel: 'Save vehicle card - button click',
    eventName: 'click_saved_vehicle_card',
  },
  clickShareVehicle: {
    conversionLabel: 'BAYcCJyc7tEZELyUm7c9',
    eventLabel: 'Share vehicle - button click',
    eventName: 'click_share_vehicle',
  },
} satisfies Record<string, AnalyticsEvent>;

export const EMAIL_REGEXP = /^\S+@\S+\.\S+$/;

export const US_ZIP_CODE_REGEXP = /^\d{5}$/;

export const FILE_LIFE_TIME = 24 * 60 * 60 * 1000;

export const LOCATION_KEY = 'location';

export const BUILD_AND_PRICE = 'Custom Order';

export const ALTERNATE_BUILD_AND_PRICE = 'Build & Price';

export const BLOG = 'Blog';

export const READ_STORY = 'Read story';

export const STH_WENT_WRONG = 'Something went wrong, please try again later';

export const ALL_SIGNED_UP = 'You’re all signed-up!';

export const MSRP = 'MSRP';

export const ESTIMATED_MSRP = 'Est MSRP';

export const POPULAR_OPTIONS = 'Popular Options';

export const COMING_SOON = 'Coming soon';

export const RESOURCES = 'Resources';

export const COMPARE = 'Compare Your ';

export const FAVORITE = 'Favorite EVs';

export const SIDE_BY_SIDE = 'Side-by-Side';

export const START_COMPARISON = 'Start Comparison';

export const PRICE_COMING_SOON = 'Price coming soon';

export const SELECT_FIRST_EV = 'Select 1st EV';

export const DEFAULT_TEXT =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.';

export const DEFAULT_US_ZIP_CODE = '90013';

export const sectionStyles =
  'py-[40px] px-[20px] md:px-[40px] lg:px-[100px] lg:py-[150px]';

export const formInputStyles =
  'flex min-h-[48px] w-full items-center rounded-xsmall border border-[1px] border-neutral-300 bg-sleetGrey-100 px-m py-s text-body1Light focus:border-brandMidnight focus:outline-none focus-visible:border-blueGrey-50 disabled:opacity-50';

export const selectedFirstEVStyle =
  'bg-lightTurquoise mr-[8px] flex h-[80px] w-full flex-col items-center justify-center rounded-[4px] border-[1px] border-brandSecondary l:h-[100px] l:border-[2px]';

export const selectedSecondEVStyle =
  'mr-[8px] flex h-[80px] w-full flex-col items-center justify-center rounded-[4px] border-[1px] border-dashed border-greyThinBorder l:h-[100px] l:border-[2px]';

export const firstEVIconStyle = 'mb-1 rounded-full bg-brandMidnight p-1';

export const secondEVIconStyle =
  'mb-1 rounded-full bg-contentBackground-success p-1';

export const FACEBOOK_URL = 'https://www.facebook.com/ev.com.official';

export const TWITTER_URL = 'https://twitter.com/EVcom';

export const INSTAGRAM_URL = 'https://www.instagram.com/evcom/';

export const TIKTOK_URL = 'https://www.tiktok.com/@ev.com';

export const LINKEDIN_URL = 'https://www.linkedin.com/company/ev-com/';

export const YOUTUBE_URL = 'https://www.youtube.com/@evdotcom';

export const USER_SELECTION_KEY = 'userSelections';

export const POPULAR_MAKES = ['Tesla', 'Chevrolet', 'Kia', 'Ford'];

export const POPULAR_MAKES_EXTENDED = [...POPULAR_MAKES, 'Hyundai', 'Rivian'];

export const HIDDEN_MAKES = [
  'Karma',
  'Jaguar',
  'smart',
  'Buick',
  'INFINITI',
  'Mitsubishi',
  'Rolls-Royce',
  'Ram',
  'Fisker',
];

export const SELL_MY_CAR_STEPS = [
  'Vehicle info',
  'Condition',
  'Ownership',
  'Take photos',
  'Get offers',
];

export const PLACEHOLDER_LABEL = 'Data coming soon';

export const CHOOSE_MAKE = 'Choose Make';

export const FIRST_EV = '1st EV';

export const SECOND_EV = '2nd EV';

export const SELECT_SECOND_EV = 'Select 2nd EV';

export const POPULAR_EVS_COMPARISON = 'Popular EVs Comparison';

export const CHOOSE_MODEL = 'Choose model';

export const STARTING = 'Starting';

export const CHOOSE_TRIM = 'Choose trim';

export const COMPARE_EVS = 'Compare EVs';

export const RESET = 'Reset';

export const BUILD_PRICE = 'Build & Price';

export const BACK = 'Back';

export const UPDATE = 'Update';

export const EDIT_EV = 'Edit EV';

export const EXPLORE_MORE_DETAILS = 'Explore more details';

export const SELECT_ANOTHER_EV =
  'Details of this Trim will be coming soon, please select another one.';

export const SHOP_EV = 'Shop EV';

export const SEARCH = 'Search';

export const EXPLORE = 'Explore';

export const COMPARE_EV = 'Compare EV';

export const SHOP_ALL = 'Shop All';

export const SHOP_NEW = 'Shop New';

export const SHOP_PREOWNED = 'Shop Pre-Owned';

export const NEWS = 'News';

export const PARTNER = 'Partner';

// 8 MB
export const FILE_UPLOAD_LIMIT_IN_BYTES = 8_000_000;

export const MY_EVS = 'My EVs';

export const COMPARE_HIGHTLIGHTS =
  'Range, DC_Charging_Time, Seating_Capacity, Battery_Warranty, Horsepower, Torque, Drive_Type, Body_Type, Cargo_Capacity, Charger_Plug_Type';

export const AFTER_LOGIN_REDIRECT_KEY = 'afterLoginRedirect';

export const BECOME_A_DEALER_ROUTE = '/partner-signup';

export const ADVANCED_FILTERS = 'Advanced Search';

export const FILTERS = 'Filters';

export const BACK_TO_TOP = 'Back to top';

export const DEFAULT_COMPARISON_EV_DETAILS = {
  make: '',
  year: '',
  model: '',
  trim: '',
  MSRP: 0,
  imageUrl: '',
};

export const DEV_AUTH_TOKEN = 'DEVTOKEN';

export const AUTH_SCHEME = 'Bearer';

export const BATTERY_ELECTRIC_VEHICLE_ABBREVIATION = 'BEV';

export const PLUG_IN_HYBRID_ELECTRIC_VEHICLE_ABBREVIATION = 'PHEV';

export const SEND_MY_MESSAGE = 'Send my message';

export const CONTACT_DEALER = 'Contact Dealer';

export const MESSAGE_SENT = 'Message Sent';

export const VIEW_MY_PROFILE = 'View my profile';

export const SEARCH_LISTINGS = 'Search listings';

export const CLOSE_WINDOW = 'Close Window';

export const RESEND_LINK = 'Resend link';

export const PRE_ORDER = 'Pre-order';

export const URL_REGEX = /(https?:\/\/[^\s]+)/g;

export const VEHICLE_HISTORY_DESCRIPTION =
  'The vehicle history information is supplied by third parties. EV.com and the Seller are not responsible for the accuracy of such information. EV.com provides this service and materials without representations or warranties of any kind, either expressed or implied. Please see Terms and Conditions for further information.';

export const VEHICLE_HISTORY_STATE_TITLE = {
  title: 'State Title Brand.',
  description: 'Issue found',
};

export const VEHICLE_HISTORY_ACCIDENTS = {
  title: 'Accident/Damage Reported.',
  description:
    'It is recommended that the vehicle is checked for repairs by a recognized professional.',
};

export const VEHICLE_HISTORY_SERVICE_REPAIR = {
  title: 'Service Repair.',
  description: 'Issue found',
};

export const VEHICLE_HISTORY_OWNERS = {
  title: 'Owners.',
  description:
    'previous owners increase the likelihood of the vehicle having issues.',
};

export const VEHICLE_HISTORY_RECALL = {
  title: 'Vehicle recall.',
};

export const S3_OEM_LOGO_LINK = 'https://ev-oem-logos.s3.amazonaws.com/svg';

export const LOADING_GIF = '/images/LightingBoltLoading.gif';

export const DEFAULT_SEARCH_MILEAGE = '200';

export const SEARCH_CONSTANTS = {
  pageSize: 15,
  initialSort: 'rankedscore_desc',
};

export const BODY_TYPES = ['Sedan', 'Hatchback', 'Luxury', 'SUV', 'Truck'];

export const VEHICLE_LISTING_REPORT_DISCLAIMER =
  'This Report was created using specific data about this vehicle obtained from third parties (including, without limitation, the dealership or private party listing the vehicle for sale) and aggregated data that EV.com obtains from publicly available data provided by third parties. The Report estimates this vehicle’s battery health, range, value, and other information. However, because the information on which the Report is based is obtained from several third-party sources, EV.com makes no warranties about the information in this Report, which may contain errors and omissions. Therefore, you should not rely solely on the information in this Report for any purpose, including purchase decision-making. You are solely responsible for your use of this Report.';

export const VEHICLE_HISTORY_DISCLAIMER =
  "Vehicle History is from third parties. EV.com  and the Seller aren't responsible for its accuracy. No warranties are provided. See Terms and Conditions for details.";

export const VEHICLE_VALUE_DISCLAIMER =
  'Price evaluations in Value section are based on listings of similar vehicles with the same or equivalent make, model, and trim. Mileage and condition are not considered when determining if this vehicle is above or below the average market price.';

export const SUPPORT_EMAIL_ADDRESS = 'support@ev.com';

export const CALCULATOR_DISCLAIMER =
  'This payment estimate is for convenience only and does not constitute a financing offer or credit guarantee.';
export const CALCULATOR_DISCLAIMER_LONG =
  'Payment Calculator is provided for convenience and does not represent a financing or leasing offer, nor a credit guarantee. It excludes charges such as title, registration, sales tax, and other fees that may apply. Costs may vary by state.';

export const FALLBACK_IMAGE = '/images/ImageComingSoon.png';

export const PRICE_HISTORY_DISCLAIMER =
  'Price History is based on similar vehicle listings but does not account for mileage or condition when comparing to the average market price.';

export const INCENTIVES_DISCLAIMER = `The Clean Vehicle Tax Credit is designed to reduce the cost of electric vehicles (EVs). Depending on your eligibility and the type of vehicle you're buying, you could receive up to $7,500 for a new EV or up to $4,000 for a used one. Individual eligibility criteria apply.`;

export const VLP_PRICE_DISCLAIMER =
  'Prices and EV savings estimates are for informational purposes only. Confirm final pricing, incentive programs, vehicle costs, and tax eligibility with the seller before purchasing. Monthly payment estimates are for convenience and do not constitute a financing offer or credit guarantee. All third-party trademarks are the property of their respective owners and do not imply endorsement or affiliation with EV.com.';

export const COST_COMPARISON_DISCLAIMER =
  'Cost comparison evaluates average EV SUVs and gasoline SUVs based on class and features. Efficiency assessments are guided by EPA standards, factoring in 45% highway and 55% city driving for 10,000 annual miles. Costs are based on national averages: $3.90 per gallon of gasoline and $0.16 per kWh for electricity. Actual savings may vary due to market changes and driving conditions. Average EV SUV consumption rating = 28kWh/100mi. Average Gasoline SUV consumption rating = 22.9MPG.';

export const CALC_DEFAULT_MODE = 'Lease';

export const CALC_FINANCE_LOAN_TERM_OPTIONS = ['36', '48', '60', '72', '84'];
export const CALC_INITIAL_FINANCE_TERM_INDEX = 3;
export const CALC_FINANCE_INITIAL_APR = 5;
export const CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT = 0.2;

export const CALC_LEASE_LOAN_TERM_OPTIONS = ['12', '24', '36', '48'];
export const CALC_INITIAL_LEASE_TERM_INDEX = 2;
export const CALC_LEASE_INITIAL_INTEREST_RATE = 0;
export const CALC_LEASE_INITIAL_DOWN_PAYMENT_PERCENT = 0.2;
export const CALC_LEASE_INITIAL_RESIDUAL_VALUE_PERCENT = 0.5;

// value corresponds to how mileage affects residual value in percentage
export const CALC_LEASE_MILEAGE_OPTIONS = [
  { text: '7,500 miles or less', value: '-1' },
  { text: 'Up to 10,000 miles', value: '1' },
  { text: 'Up to 12,000 miles', value: '2' },
  { text: 'More than 12,000 miles', value: '3' },
];

export const GAS_SAVINGS_INITIAL_TERM = '5';

// gas:MPG , electric: kwh/100mi
export const STYLE_TO_EFFICIENCY: Readonly<
  Record<string, { gas: number; electric: number }>
> = {
  Sedan: { gas: 28.2, electric: 28 },
  Hatchback: { gas: 27.3, electric: 28 },
  Coupe: { gas: 19, electric: 40 },
  Luxury: { gas: 21.2, electric: 38 },
  SUV: { gas: 22.9, electric: 35 },
  Truck: { gas: 19.1, electric: 48 },
  Minivan: { gas: 21.6, electric: 38 },
  'Mini Van': { gas: 21.6, electric: 38 },
  Wagon: { gas: 23, electric: 35 },
  Van: { gas: 18, electric: 38 },
};

export const EMPTY_FILTERS: FilterType = {
  postal: '',
  distance: DEFAULT_SEARCH_MILEAGE,
  condition: '',
  fuelType: '',
  year_start: '',
  year_end: '',
  price_start: '',
  price_end: '',
  make: [],
  model: [],
  skip: 0,
  limit: 15,
  radius_km: '',
  maxMileage: '',
  sort: '',
  dealer_group: '',
  dealer: '',
  body: [],
  features: [],
  has_ev_incentives: false,
  vector_search: '',
  incentive_zip: DEFAULT_US_ZIP_CODE,
};

export const SORT_BY_OPTIONS = [
  {
    text: 'Price: Low to High',
    value: 'price_asc',
  },
  {
    text: 'Price: High to Low',
    value: 'price_desc',
  },
  {
    text: 'Best Match',
    value: 'rankedscore_desc',
  },
  {
    text: 'Newly Listed',
    value: 'dateinstock_desc',
  },
  {
    text: 'Year: Old to New',
    value: 'year_asc',
  },
  {
    text: 'Year: New to Old',
    value: 'year_desc',
  },
  {
    text: 'Miles: Low to High',
    value: 'kms_asc',
  },
  {
    text: 'Miles: High to Low',
    value: 'kms_desc',
  },
  ...(process.env.NEXT_PUBLIC_SORT_BY_DISTANCE === 'true'
    ? [
        {
          text: 'Distance: Near to Far',
          value: 'distance_asc',
        },
      ]
    : []),
];

export const DEFAULT_COSTS: Readonly<Required<GasVsElectricCosts>> = {
  mileagePerYear: '15000',
  gasolineCost: '3.90',
  electricityCost: '0.16',
};

export const ChargerTypes = {
  SAEJ1772: {
    type: 'SAE J1772',
    icon: IconIds.SAEJ1772Connector,
  },
  CCS: {
    type: 'CCS',
    icon: IconIds.CCSConnector,
  },
  NACS: {
    type: 'NACS',
    icon: IconIds.NACSConnector,
  },
  CHAdeMo: {
    type: 'CHAdeMo',
    icon: IconIds.CHAdeMOConnector,
  },
  NA: {
    type: 'N/A',
    icon: IconIds.NAConnector,
  },
};

export const AUTH_CONFIG: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID ?? '',
      userPoolClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID ?? '',
      loginWith: {
        oauth: {
          domain: process.env.NEXT_PUBLIC_OAUTH_DOMAIN ?? '',
          redirectSignIn: [process.env.NEXT_PUBLIC_REDIRECTION ?? ''],
          redirectSignOut: [process.env.NEXT_PUBLIC_REDIRECTION ?? ''],
          responseType: 'code',
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
        },
      },
    },
  },
};

export const POPULAR_SEARCH_MAKES = [
  { make: 'Tesla', value: 'Tesla', makeIcon: { make: 'Tesla' } },
  { make: 'Chevrolet', value: 'Chevrolet', makeIcon: { make: 'Chevrolet' } },
  { make: 'Kia', value: 'Kia', makeIcon: { make: 'Kia' } },
  { make: 'Hyundai', value: 'Hyundai', makeIcon: { make: 'Hyundai' } },
  { make: 'Ford', value: 'Ford', makeIcon: { make: 'Ford' } },
  { make: 'Volkswagen', value: 'Volkswagen', makeIcon: { make: 'Volkswagen' } },
];

export const POPULAR_SEARCHES = [
  { make: 'Hyundai', model: 'Ioniq 6', value: 'Hyundai Ioniq 6' },
  { make: 'Tesla', model: 'Model 3', value: 'Tesla Model 3' },
  { make: 'BMW', model: 'i4', value: 'BMW i4' },
  { make: 'Ford', model: 'Mustang Mach-E', value: 'Ford Mustang Mach-E' },
  { make: 'Lucid', model: 'Air', value: 'Lucid Air' },
];
